<template>
  <div ref="worker" class="worker">
    <div class="top">
      <img src="https://cdn.cailu88.com/jingxuanshi/client_worker_buy.png"/>
    </div>
    <div class="bottom">
      <div class="image">
        <img src="https://cdn.cailu88.com/jingxuanshi/client_subsidies_button@2x.png"
             v-if="channel !== 'weixin' && channel !== 'zfb'" @click="isAuth()"/>
      </div>
      <div class="content">
        <div style="padding: 20px 28px">
          <p class="copy">{{ copyContent }}</p>
          <van-button @click="copyText($event)">复制口令</van-button>
          <p class="text">
            <span>复制文案</span>
            <van-icon name="arrow"/>
            <span>好友下单</span>
            <van-icon name="arrow"/>
            <span>你赚佣金</span>
          </p>
        </div>
      </div>
    </div>
    <Loading :click="click"></Loading>
  </div>
</template>
<script>
import Vue from 'vue'
import { Button, Icon, Toast } from 'vant'
import Loading from '../../components/loading'
import baseUrl from '@/utils/baseUrl'
import clipboard from '@/utils/copy'

const api = require('../../utils/api').api

Vue.use(Button)
Vue.use(Icon)
Vue.use(Toast)

export default {
  name: 'worker',
  data () {
    return {
      isAndroidApp: false,
      isIOSApp: false,
      authStatus: false,
      token: '',
      version: '',
      channel: '',
      url: '',
      copyContent: '8小二呕心整理的内购爆款清单，第二件0元！【点击复制生成专属口令】，进入【T❥B】抢购:/',
      click: true,
      id: '20150318020000462',
    }
  },
  components: {
    Loading,
  },
  created () {
    this.token = this.$route.query.token || '';
    this.version = this.$route.query.version || '';
    this.channel = this.$route.query.channel || '';
    this.appClient = this.$route.query.appClient || '';
  },
  mounted () {
  },
  methods: {

    //复制
    copyText (event) {
      let that = this
      if (!this.click) {
        return false
      }
      this.click = true
      this.$.ajax({
        url: baseUrl.baseUrl + api.auth,
        type: 'get',
        headers: {
          'token': that.token,
          'version': that.version,
          'channel': that.channel,
          'appClient': that.appClient,
          'Content-Type': 'application/json;charset=UTF-8'
        },
        async: false,
        success: function (res) {
          if (res.code !== 'USER_UN_LOGIN' && res.code) {
            if (res.data.authStatus == 0) {
              that.click = true
              that.$h5AppLink('openTbAuth', {
                authUrl: res.data.authUrl,
                backUrl: `${baseUrl.pageUrl}/worker`
              })
            } else {
              that.$.ajax({
                url: baseUrl.baseUrl + api.strategyUrl,
                type: 'post',
                headers: {
                  'token': that.token,
                  'version': that.version,
                  'channel': that.channel,
                  'appClient': that.appClient,
                  'Content-Type': 'application/json;charset=UTF-8'
                },
                data: JSON.stringify({
                  shareScene: true,
                  initUrl: 'https://mo.m.taobao.com/union/page_union_last_list_ty12.12',
                  tbActivityMaterialId: '-1',
                  clientType: 2
                }),
                async: false,
                success: function (res_t) {
                  that.click = true
                  Toast.success({
                    duration: 500, // 持续展示 toast
                    message: '复制成功'
                  })
                  that.copyContent = res_t.data.shareContent
                }
              })
            }
          } else {
            that.click = true
            that.isLogin()
            return false
          }

        }
      })
      this.click = true
      clipboard(this.copyContent, event)
    },

    // 进入会场
    jump () {
      let paramObj = {
        version: this.version,
        url: this.url,
        backUrl: `${baseUrl.pageUrl}/worker`
      }
      this.$h5AppLink('version', paramObj)
    },

    // 授权
    isAuth (type) {
      if (!this.click) {
        return false
      }
      if (this.url) {
        this.click = true
        setTimeout(() => {
          this.jump()
        })
        return
      }
      this.$axios(api.auth, {}).then(res => {
        this.click = true
        if (res.data.data.authStatus == 0) {
          let event = 'openTbAuth'
          let param = {
            authUrl: res.data.data.authUrl,
            backUrl: `${baseUrl.pageUrl}/worker`
          }
          this.$h5AppLink(event, param)
        } else {
          this.authStatus = true
          this.handleGetUrl(type)
        }
      }).catch(err => {
        this.click = true
        this.isLogin()
      })
    },

    // 获取url
    handleGetUrl (type) {
      this.$axios.post(api.strategyUrl, {
        tbActivityMaterialId: '-1',
        initUrl: 'https://mo.m.taobao.com/union/page_union_last_list_ty12.12'
      }).then(res => {
        this.url = res.data.data.spreadUrl
        this.copyContent = res.data.data.shareContent
        this.click = true
        if (type) {
          return false
        }
        setTimeout(() => {
          this.jump()
        })
      }).catch(err => {
        Toast.fail(err.data.message)
        this.click = true
        console.log(err)
      })
    },

    // 登陆
    isLogin () {
      let event = 'login'
      let param = {
        backUrl: `${baseUrl.pageUrl}/worker`
      }
      this.$h5AppLink(event, param)
    },
  },
}
</script>
<style lang="less" scoped>
.worker {
  min-height: 100vh;
  background: #fc6e22;

  .top {
    line-height: 0;

    img {
      width: 100%;
    }
  }

  .bottom {
    width: 100%;
    text-align: center;

    .image {
      margin-bottom: 36px;

      img {
        width: 275px;
      }
    }

    .content {
      margin: 0 auto;
      width: 340px;
      background: #FFFFFF;
      border-radius: 12px;

      div {
        display: flex;
        flex-direction: column;
      }

      p {
        font-size: 15px;
        font-weight: 400;
        color: #333333;
        line-height: 21px;
      }

      .copy {
        margin-bottom: 20px;
        line-height: 21px;
        text-align: left;
        //overflow: hidden;
        //text-overflow: ellipsis;
        //display: -webkit-box;
        //-webkit-line-clamp: 3; //想显示多少行就给多大值
        //-webkit-box-orient: vertical;
      }

      .van-button {
        margin: 0 auto;
        width: 250px;
        height: 50px;
        background: #fc6e22;
        font-weight: 400;
        font-size: 16px;
        color: #ffffff;
        border-radius: 25px;
        border: none;
      }

      .text {
        margin-top: 15px;
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        color: #fc6e22;
        line-height: 20px;

        .van-icon {
          margin: 0 12px;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
